@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-bg-color: #F0F0F2;  /* SNOWFLAKE */
  --main-fonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --code-fonts: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  margin: 0;
  font-family: var(--main-fonts);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
}

code {
  font-family: var(--code-fonts)
}
