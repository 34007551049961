.general-container {
  @apply max-w-5xl mx-auto grid place-items-center h-screen py-10 px-4 lg:px-0;
}
.data-local-time {
  @apply absolute hidden md:block top-10 left-10 text-[#ABABAB] text-xs;
}
.data-screen-resolution {
  @apply absolute hidden md:block bottom-10 left-10 text-[#ABABAB] text-xs;
}
.data-last-visit {
  @apply absolute hidden md:block bottom-10 right-10 text-[#ABABAB] text-xs;
}